<script>


export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage = this.block.fields?.Image?.id
      && this.$toLitiumMediaUrl(this.block.fields?.Image?.id, { maxWidth: 100 });

    const lowResPhoneImage = this.block.fields?.PhoneImage?.id
      && this.$toLitiumMediaUrl(this.block.fields?.PhoneImage?.id, { maxWidth: 100 });

    return {
      imgUrl: lowResolutionImage,
      phoneImgUrl: lowResPhoneImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    const container = this.$refs.mediaContainer;

    if (this.imgUrl) {
      const media = this.block.fields.Image;
      const url = this.loadMedia(media, container);
      this.$nextTick(() => {
        const img = new Image();

        img.onload = () => {
          this.isImageDownloaded = true;
          this.imgUrl = url;
        };

        img.src = url;
      });
    }

    if (this.phoneImgUrl) {
      const phoneMedia = this.block.fields.PhoneImage;
      const url = this.loadMedia(phoneMedia, container);
      this.$nextTick(() => {
        const img = new Image();

        img.onload = () => {
          this.isImageDownloaded = true;
          this.phoneImgUrl = url;
        };

        img.src = url;
      });
    }
  },
  computed: {
    blockStyle() {
      const style = {};

      if (this.block.fields?.Height) {
        if (this.block.fields.Height.slice(-1) === '%') {
          style.height = `${this.block.fields.Height.slice(0, -1)}vh`;
        } else {
          style.height = this.block.fields.Height;
        }
      } else {
        style.height = '50vh';
      }

      style.background = this.block.fields?.BackgroundColorText || '';

      return style;
    },
    showImage() {
      return this.block.fields?.Image?.id;
    },
    showPhoneImage() {
      return this.block.fields?.PhoneImage?.id;
    },
    showVideo() {
      return this.block.fields?.Video?.id;
    },
    imageAlt() {
      return this.block.fields?.Image?.alt || undefined;
    },
    phoneImageAlt() {
      return this.block.fields?.PhoneImage?.alt || undefined;
    },
    video() {
      return this.resizedMedia(this.block.fields?.Video?.id);
    },
    imageFocalPointStyle() {
      const style = {};
      style.objectPosition = this.getMediaObjectPosition(this.block.fields.Image.id);
      return style;
    },
    videoFocalPointStyle() {
      const style = {};
      style.objectPosition = this.getMediaObjectPosition(this.block.fields.Video.id);
      return style;
    },
    linkUrl() {
      return this.block.fields?.LinkToCustomUrl
              || this.block.fields?.LinkToPage?.href
              || this.block.fields?.LinkToCategory?.href
              || this.block.fields?.LinkToProduct?.href
              || null;
    },
    overlayPositionsByDevice() {
      return this.block.fields?.BannerOverlays?.reduce((res, overlay) => {
        const positions = [overlay.OverlayPositionPhone?.value || 'center',
                           overlay.OverlayPositionTablet?.value || 'center',
                           overlay.OverlayPositionDesktop?.value || 'center'];

        new Set(positions).forEach((pos) => {
          if (pos === 'hidden') return;
          const showOn = ['phone', 'tablet', 'desktop'].filter((_, i) => positions[i] === pos);

          res[pos] = res[pos] || [];
          res[pos].push({
            showOn,
            overlay,
          });
        });
        return res;
      }, {});
    },
  },
  methods: {
    getOverlayClassFor(item) {
      const classes = [
        'text-editor-content',
        'hero__overlay-item',
        `hero__overlay-item--phone-text-${item.overlay.TextAlignPhone?.value || 'center'}`,
        `hero__overlay-item--tablet-text-${item.overlay.TextAlignTablet?.value || 'center'}`,
        `hero__overlay-item--desktop-text-${item.overlay.TextAlignDesktop?.value || 'center'}`,
        `hero__overlay-item--${this.block.SystemId}`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`hero__overlay-item--${device}`);
      });
      return classes;
    },
    getColor(item) {
      const style = {};
      if (item?.overlay?.ColorSelect?.value) {
        style.color = `#${item.overlay.ColorSelect.value}`;
      } else if (item?.overlay?.ColorCustom) {
        style.color = `${item.overlay.ColorCustom}`;
      }
      return style;
    },
    getMediaObjectPosition(media) {
      return media?.FocalPoint ? `${media.FocalPoint.left}% ${media.FocalPoint.top}%` : '50% 50%';
    },
    resizedMedia(id) {
      const width = this.$refs?.mediaContainer?.getBoundingClientRect()?.width;

      return this.$toLitiumMediaUrl(id, { maxWidth: width });
    },
    loadMedia(media, container) {
      const mediaAspectRatio = media.width / media.height;
      const containerAspectRatio = container.clientWidth / container.clientHeight;

      return this.$toLitiumMediaUrl(media?.id,
        {
          ...(mediaAspectRatio < containerAspectRatio
            ? { maxWidth: container.clientWidth }
            : { maxHeight: container.clientHeight }),
        });
    },
  },
};
</script>
<template>
  <component
    :is="linkUrl? 'a': 'div'"
    class="hero__container"
    :href="linkUrl"
    :style="blockStyle"
    ref="mediaContainer"
  >
    <img
      v-if="!showVideo && showImage"
      :class="['hero__image',
        {'hero__desktop-image' : showPhoneImage,
        'hero__image-loading' : !isImageDownloaded}]"
      :style="imageFocalPointStyle"
      :src="imgUrl"
      :alt="imageAlt"
    >
    <img
      v-if="!showVideo && showPhoneImage"
      :class="['hero__image',
        {'hero__phone-image': showVideo || showImage},
        {'hero__image-loading' : !isImageDownloaded}]"
      :src="phoneImgUrl"
      :alt="phoneImageAlt"
    >
    <video
      v-if="showVideo"
      class="hero__video"
      :style="videoFocalPointStyle"
      autoplay
      muted
      loop
      playsinline
    >
      <source :src="video" type="video/mp4">
    </video>
    <div
      v-for="(items, position) in overlayPositionsByDevice"
      :key="position"
      class="hero__overlay"
      :class="`hero__overlay--${position}`"
    >
      <div class="hero__overlay-container">
        <div
          v-for="(item, idx) in items"
          :key="idx"
          v-html="item.overlay.TextOverlay"
          :class="getOverlayClassFor(item)"
          :style="getColor(item)"
        >
        </div>
      </div>
    </div>
  </component>
</template>
<style>
  .hero__container:not([href]) {
    cursor: default;
  }

  .hero__container {
    position: relative;
    display: block;
    text-decoration: none;
    width: 100vw;
    max-width: 100%;
  }

  .hero__video,
  .hero__image {
    width: 100%;
    height:inherit;
    object-fit: cover;
    position: absolute;
  }

  .hero__image-loading {
    filter: blur(5px);
  }

  .hero__overlay {
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 1260px;
    display: flex;
    flex-direction: column;
    padding: 70px 15px;
  }

  .hero__overlay-item {
    padding: 10px;
    display: none;
  }

  .hero__overlay-item > p,
  .hero__overlay-item > h1,
  .hero__overlay-item > h2,
  .hero__overlay-item > h3,
  .hero__overlay-item > h4,
  .hero__overlay-item > h5,
  .hero__overlay-item > h6 {
    color: inherit;
    margin: 0;
  }

  .hero__overlay-item > h1 {
    line-height: 41px;
  }

  .hero__overlay-item > p {
    line-height: 24px;
  }

  @media (--phone) {
    .hero__desktop-image {
      display: none;
    }

    .hero__overlay {
      padding: 30px 15px;
    }

    .hero__overlay-item--phone {
      display: block;
    }

    .hero__overlay-item {
      width: 100%;
    }

    .hero__overlay-item--phone-text-left {
      text-align: left;
    }

    .hero__overlay-item--phone-text-center {
      text-align: center;
    }

    .hero__overlay-item--phone-text-right {
      text-align: right;
    }
  }

  @media (--tablet) {
    .hero__phone-image {
      display: none;
    }

    .hero__overlay-item {
      padding: 1rem;
    }

    .hero__overlay-item--tablet {
      display: block;
    }

    .hero__overlay-item--tablet-text-left {
      text-align: left;
    }

    .hero__overlay-item--tablet-text-center {
      text-align: center;
    }

    .hero__overlay-item--tablet-text-right {
      text-align: right;
    }
  }

  @media (--desktop) {
    .hero__phone-image {
      display: none;
    }

    .hero__overlay-item--desktop {
      display: block;
    }

    .hero__overlay-item--desktop-text-left {
      text-align: left;
    }

    .hero__overlay-item--desktop-text-center {
      text-align: center;
    }

    .hero__overlay-item--desktop-text-right {
      text-align: right;
    }
  }

  /* Overlay positions */
  .hero__overlay--top {
    justify-content: flex-start;
  }

  .hero__overlay--topleft {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .hero__overlay--topright {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .hero__overlay--left {
    align-items: flex-start;
    justify-content: center;
  }

  .hero__overlay--right {
    align-items: flex-end;
    justify-content: center;
  }

  .hero__overlay--center {
    align-items: center;
    justify-content: center;
  }

  .hero__overlay--bottom {
    justify-content: flex-end;
  }

  .hero__overlay--bottomleft {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .hero__overlay--bottomright {
    align-items: flex-end;
    justify-content: flex-end;
  }
</style>
